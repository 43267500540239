// globals.ts
import { Injectable } from '@angular/core';
import { UsersService } from './theme/services/users';

@Injectable()
export class Globals {
    user: any;
    loading: boolean
    promise: Promise<any>;
    public firstEntry = 0;

    constructor(private _users_service: UsersService){
    }

    loadUser(){
        if(this.promise)
            return this.promise;
        this.promise = new Promise((resolve, reject) => {
            if(!this.user && !this.loading){
                this.loading = true;
                this._users_service.loadUserData().subscribe((res) => {
                    this.user = res["data"];
                    this.user["_permissions"] = [];
                    this.user["_roles"] = [];
                    this.user["permissions"].forEach(permission => {
                        this.user["_permissions"].push(permission.slug);
                    });
                    this.user["roles"].forEach(role => {
                        role.permissions.forEach(permission => {
                            this.user["_permissions"].push(permission.slug);
                        });
                        this.user["_roles"].push(role.slug);
                    });
                    this.loading = false;
                    resolve(this.user);
                });
            }
            else{
                resolve(this.user);
            }
        });
        return this.promise;
    }

    hasPermission(permission){
        if(this.user && this.user._permissions.indexOf(permission) > -1){
            return true;
        }
        return false;
    }
    
    hasRole(role){
        if(this.user && this.user._roles.indexOf(role) > -1){
            return true;
        }
        return false;
    }
}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
	public date;

    constructor() {

    }
    ngOnInit() {
    	this.date = (new Date()).getFullYear();
    }

}
import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "../auth/_guards/auth.guard";

const routes: Routes = [
    {
        "path": "",
        "component": ThemeComponent,
        "canActivate": [AuthGuard],
        "children": [
            {
                "path": "index",
                "loadChildren": ".\/pages\/default\/index\/index.module#IndexModule"
            },
            {
                "path": "users",
                "loadChildren": ".\/pages\/default\/users\/user.module#UserModule"
            },
            {
                "path": "roles",
                "loadChildren": ".\/pages\/default\/roles\/roles.module#RolesModule"
            },
            {
                "path": "companies",
                "loadChildren": ".\/pages\/default\/companies\/companies.module#CompaniesModule"
            },
            {
                "path": "campaigns",
                "loadChildren": ".\/pages\/default\/campaigns\/campaigns.module#CampaignsModule"
            },
            {
                "path": "validate",
                "loadChildren": ".\/pages\/default\/validateVoucher\/validateVoucher.module#ValidateVoucherModule"
            },
            {
                "path": "lps",
                "loadChildren": ".\/pages\/default\/lps\/landing-pages.module#LandingPagesModule"
            },
            {
                "path": "segments",
                "loadChildren": ".\/pages\/default\/segments\/segments.module#SegmentsModule"
            },
            {
                "path": "services",
                "loadChildren": ".\/pages\/default\/services\/services.module#ServicesModule"
            },
            {
                "path": "cupons-resgatados",
                "loadChildren": ".\/pages\/default\/vounchers\/vounchers.module#VounchersModule"
            },
            {
                "path": "404",
                "loadChildren": ".\/pages\/default\/not-found\/not-found.module#NotFoundModule"
            },
            {
                "path": "",
                "redirectTo": "index",
                "pathMatch": "full"
            }
        ]
    },
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThemeRoutingModule { }
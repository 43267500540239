import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { CampaignService } from '../../services/campaigns';
import { Globals } from '../../../globals';
import { AuthenticationService } from "../../../auth/_services/authentication.service";

declare let mLayout: any;
declare let $: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
    public campaigns: any;
    public user: any;

    constructor(private _script: ScriptLoaderService, private _campaigns_service: CampaignService, private globals: Globals,
        private _authService: AuthenticationService) {
        this.campaigns = [];
        this.globals.loadUser().then((user) => {
            this.user = user;
        });
    }
    ngOnInit() {

    }
    ngAfterViewInit() {
        mLayout.initHeader();
    }
    ngLoadReports() {
        if(!this.campaigns.length){
            this._campaigns_service.index().subscribe(
                res => this.campaigns = res["data"],
                err => err
            );
        }
    }
    logout(){
        Helpers.setLoading(true);
        this._authService.logout();
        setTimeout(() => {
            location.href = $("base").attr("href");
        }, 1000);
    }
}
export class HttpClientHelper {
    static baseStageURL = `https://stageapi.ouvi.io`;
    static baseProdURL = `https://prodapi.ouvi.io`;
    static baseLocalURL = `http://172.16.0.125`;

    static baseStageLPURL = `https://stagelp.ouvi.io/`;
    static baseProdLPURL = `https://lp.ouvi.io/`;
    static baseLocalLPURL = `https://172.16.0.125:4200/`;

    static baseURL = HttpClientHelper.baseStageURL;
    static baseLPURL = HttpClientHelper.baseStageLPURL;
}

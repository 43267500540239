import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogoutComponent } from "./auth/logout/logout.component";
import { RecoverPasswordComponent } from './auth/recover-password/recover-password.component';

const routes: Routes = [
    { path: 'login', loadChildren: './auth/auth.module#AuthModule' },
    { path: 'logout', component: LogoutComponent },
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    { path: 'validate-voucher', loadChildren: './theme/pages/default/validateVoucher/validateVoucher.module#ValidateVoucherModule' },
    { path: 'recover-password', component: RecoverPasswordComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
import { Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import "rxjs/add/operator/map";
import { HttpClientHelper } from "../../http-client-helper";
import { JwtHelper } from "angular2-jwt";

@Injectable()
export class AuthenticationService {

    constructor(private http: Http) {
    }

    login(userName: string, password: string) {
        return this.http.post(`${HttpClientHelper.baseURL}/users/login`, { userName: userName, password: password })
            .map((response: Response) => {
                // login successful if there's a jwt token in the response
                let user = response.json();
                if (user && user.data && user.data.jwt) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', user.data.jwt);
                }
                return user.data;
            });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }

    getToken(): any {
        return localStorage.getItem('currentUser');
    }

    getUser() {
        return this.http.get(`${HttpClientHelper.baseURL}/sessions`);
    }

    public isAuthenticated(): boolean {
        // get the token
        const token = this.getToken();
        // return a boolean reflecting 
        // whether or not the token is expired

        return (new JwtHelper()).isTokenExpired(token.sessionId);
    }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { Router } from "@angular/router";
import { ScriptLoaderService } from '../../_services/script-loader.service';
import { Helpers } from '../../helpers';
import { LoginCustom } from './../_helpers/login-custom';

import { HttpClientHelper } from "../../http-client-helper";

declare var Swal: any;
@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './recover-password.component.html',
})
export class RecoverPasswordComponent implements OnInit {
    RecoverPasswordForm: FormGroup;
    errors = null;
    constructor(
        public fb: FormBuilder,
        route: ActivatedRoute,
        private http: Http,
        private _router: Router,
        private _script: ScriptLoaderService,
    ) {
        this.RecoverPasswordForm = this.fb.group({
            email: [''],
            password: [''],
            password_confirmation: [''],
            passwordToken: [''],
            agree: [''],
        });
        
        route.queryParams.subscribe((params) => {
            this.RecoverPasswordForm.controls['passwordToken'].setValue(
                params['token']
            );
        });
    }
    ngOnInit() {
        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/default/base/scripts.bundle.js'], true).then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
            });
    }
    onSubmit() {
        this.http.post(`${HttpClientHelper.baseURL}/users/password-reset`, this.RecoverPasswordForm.value).map((response: Response) => response.json()).subscribe(
            (result) => {
                Swal.fire({
                    title: 'Sucesso',
                    text: 'Senha alterada com sucesso!',
                    icon: 'success',
                });
                this.RecoverPasswordForm.reset();
                this._router.navigate(['/']);
            },
            (error) => {
                this.handleError(error);
            }
        );
    }
    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        Swal.fire({
            title: 'Oops!',
            text: 'Ocorreu um erro ao alterar a senha.',
            icon: 'error',
        });
        return throwError(() => {
            errorMessage;
        });
    }
}
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as moment from "moment";
import { Globals } from '../../../globals';

declare var $: any
@Component({
    selector: "app-terms-conditions-modal",
    templateUrl: "./modal.component.html",
    encapsulation: ViewEncapsulation.None,
})

export class TermsConditionsModalModule implements OnInit
{
    constructor(private globals: Globals)
    {
    }

    ngOnInit()
    {
        //
    }
}
import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";

import { User } from "../_models/index";
import { HttpClientHelper } from "../../http-client-helper";

@Injectable()
export class UserService {
    constructor(private http: Http) {
    }

    verify() {
        return this.http.get(`${HttpClientHelper.baseURL}/auth/verify`, this.jwt()).map((response: Response) => response.json());
    }

    forgotPassword(email: string) {
        return this.http.post(`${HttpClientHelper.baseURL}/users/reset-password-request`, { email: email }).map((response: Response) => response.json());
    }

    getAll() {
        return this.http.get('/api/users', this.jwt()).map((response: Response) => response.json());
    }

    getById(id: number) {
        return this.http.get('/api/users/' + id, this.jwt()).map((response: Response) => response.json());
    }

    create(user: User) {
        return this.http.post('/api/users', user, this.jwt()).map((response: Response) => response.json());
    }

    update(user: User) {
        return this.http.put('/api/users/' + user.id, user, this.jwt()).map((response: Response) => response.json());
    }

    delete(id: number) {
        return this.http.delete('/api/users/' + id, this.jwt()).map((response: Response) => response.json());
    }

    changePassword(id: number, userName: string, password: string){
        return this.http.post(`${HttpClientHelper.baseURL}/users/change-password`, { id: id, userName: userName, password: password }, this.jwt()).map((response: Response) => response.json());
    }

    resetPassword(data: any) {
        return this.http.post(
            `${HttpClientHelper.baseURL}/users/password-reset`,
          data
        );
    }

    // private helper methods

    private jwt() {
        // create authorization header with jwt token
        let currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser });
            return new RequestOptions({ headers: headers });
        }
        else
        {
            let headers = new Headers({ 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'content-type', 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS' });
            return new RequestOptions({ headers: headers });
        }
    }
}
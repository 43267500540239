import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Globals } from '../../../globals';

declare let mLayout: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {
    public user: any;

    constructor(private globals: Globals) {
        this.globals.loadUser().then((user) => {
            this.user = user;
        });
    }
    ngOnInit() {
    }
    ngAfterViewInit() {

        mLayout.initAside();

    }

}
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClientHelper } from "../../http-client-helper";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class UsersService {

    constructor(private http: HttpClient) {
    }

    index(page = 1, limit = 10, q = '', orderby_field = 'firstname', orderby_seq = 'asc') {
        return this.http.get(`${HttpClientHelper.baseURL}/users`, {params: 
            {page: page.toString(), limit: limit.toString(), q: q,
                orderby_field: orderby_field,
                orderby_seq: orderby_seq
            }
        });
    }

    show(id) {
        return this.http.get(`${HttpClientHelper.baseURL}/users/${id}`);
    }

    showByCompanyId(companyId) {
        return this.http.get(`${HttpClientHelper.baseURL}/users`, {
            params: {
                companyId: companyId
            }
        });
    }

    update(user) {
        if(user.id)
            return this.http.put(`${HttpClientHelper.baseURL}/users/${user.id}`, user);
        return this.http.post(`${HttpClientHelper.baseURL}/users`, user);
    }

    definirSenhaTemporaria(user) {
        return this.http.post(`${HttpClientHelper.baseURL}/definirSenhaTemporaria`, user);
    }

    enviarEmailDados(user) {
        return this.http.post(`${HttpClientHelper.baseURL}/enviarEmailDados`, user);
    }

    importarUsuarios(users) {
        return this.http.post(`${HttpClientHelper.baseURL}/importarUsuarios`, users);
    }

    loadUserData(){
        return this.http.get(`${HttpClientHelper.baseURL}/user`)
    }

    showRoles(page = 1, limit = 10, q = '', orderby_field = 'id', orderby_seq = 'asc') {
        return this.http.get(`${HttpClientHelper.baseURL}/roles`, {params: 
            {page: page.toString(), limit: limit.toString(), q: q,
                orderby_field: orderby_field,
                orderby_seq: orderby_seq
            }
        });
    }
}

import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClientHelper } from "../../http-client-helper";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from '../../../app/auth/_services';

@Injectable()
export class CampaignService {

    constructor(private http: HttpClient, public auth: AuthenticationService) {
    }

    index(page = 1, limit = 10, q = '', orderby_field = 'title', orderby_seq = 'asc', params = {}) {
        params = {
            ...params,
            ...{
                page: page.toString(), limit: limit.toString(), q: q,
                orderby_field: orderby_field,
                orderby_seq: orderby_seq
            }
        }
        return this.http.get(`${HttpClientHelper.baseURL}/campaigns`, {params: params});
    }

    show(id) {
        return this.http.get(`${HttpClientHelper.baseURL}/campaigns/${id}`);
    }

    showCouponTemplate(campaignid) {
        return this.http.get(`${HttpClientHelper.baseURL}/campaigns/${campaignid}/coupontemplate`)
    }

    showStores(campaignid) {
        return this.http.get(`${HttpClientHelper.baseURL}/campaigns/${campaignid}/stores`)
    }

    update(campaign) {
        if(campaign.campaignid)
            return this.http.put(`${HttpClientHelper.baseURL}/campaigns/${campaign.campaignid}`, campaign);
        return this.http.post(`${HttpClientHelper.baseURL}/campaigns`, campaign);
    }

    updateCouponTemplate(campaignid, couponTemplate) {
        return this.http.post(`${HttpClientHelper.baseURL}/campaigns/${campaignid}/coupontemplate`, couponTemplate)
    }

    updateStores(campaignid, stores){
        return this.http.post(`${HttpClientHelper.baseURL}/campaigns/${campaignid}/stores`, stores)
    }

    exportVoucherCodes(campaignid) {
        return `${HttpClientHelper.baseURL}/campaigns/${campaignid}/voucher-codes?excel=true&token=${this.auth.getToken()}`;
    }
    
    indexVoucherCodes(campaignid, page = 1, limit = 10, q = '', orderby_field = 'id', orderby_seq = 'asc') {
        return this.http.get(`${HttpClientHelper.baseURL}/campaigns/${campaignid}/voucher-codes`, {params: 
            {page: page.toString(), limit: limit.toString(), q: q,
                orderby_field: orderby_field,
                orderby_seq: orderby_seq
            }
        });
    }

    countSmsChars(campaignid, msg) {
        return this.http.post(`${HttpClientHelper.baseURL}/campaigns/${campaignid}/count-sms-chars`, {msg: msg})
    }

    //Legacy
    generateCoupons(campaign_id, data){
        return this.http.post(`${HttpClientHelper.baseURL}/legacy/campaigns/${campaign_id}/vouchers/generate`, data);
    }

    // Open
    showOpen(campaign_id, as = false){
        return this.http.get(`${HttpClientHelper.baseURL}/open/campaigns/${campaign_id}`, (as ? {params: {associated_stores : "true"}} : {}));
    }
}
import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { UserService } from './_services/user.service';
import { AlertComponent } from './_directives/alert.component';
import { LoginCustom } from './_helpers/login-custom';
import { Helpers } from '../helpers';
import { Globals } from '../globals';

declare var Swal: any;
declare var $: any;
@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login-1.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;

    @ViewChild('alertSignin',
        { read: ViewContainerRef }) alertSignin: ViewContainerRef;
    @ViewChild('alertSignup',
        { read: ViewContainerRef }) alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass',
        { read: ViewContainerRef }) alertForgotPass: ViewContainerRef;

    constructor(
        private _router: Router,
        private _script: ScriptLoaderService,
        private _userService: UserService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _alertService: AlertService,
        private cfr: ComponentFactoryResolver,
        private globals: Globals) {
    }

    ngOnInit() {
        this.model.remember = true;
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || null;
        this._router.navigate([this.returnUrl]);

        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/default/base/scripts.bundle.js'], true).then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
            });
    }

    signin() {
        this.loading = true;
        this._authService.login(this.model.userName, this.model.password).subscribe(
            data => {
                this.globals.firstEntry = this.globals.firstEntry+1;
                if(data.user['firstaccess'] == 1)
                {
                    this.model = {id: data.user['id'], token: this._authService.getToken()};
                    this._authService.logout();
                    
                    this.showAlert('alertSignup');
                    this._alertService.success(
                        'Este é o seu primeiro acesso. Por favor, troque sua senha.',
                        true);
                    this.loading = false;
                    LoginCustom.displaySignUpForm();
                }
                else
                {
                    if(data.user['startscreen'] == '/validate'){
                        this._router.navigate([data.user['startscreen']+'/'+data.user['companyid']]);
                    }
                    else{
                        this._router.navigate([data.user['startscreen']]);
                    }
                }
            },
            error => {
                Swal.fire({
                    title: 'Oops!',
                    text: 'Não foi possível realizar login. Por favor, verifique seu usuário e senha e tente novamente!',
                    icon: 'error',
                })
                this.loading = false;
            });
    }

    firstAccess()
    {
        this.loading = true;
        this._userService.changePassword(this.model.id, this.model.userName, this.model.password).subscribe(
            data => {
                this.showAlert('alertSignin');
                this._alertService.success(
                    'Senha alterada com sucesso. Realize o login novamente.',
                    true);
                this.loading = false;
                LoginCustom.displaySignInForm();
                this.model = {};
            },
            error => {
                this.showAlert('alertSignup');
                console.log(error);
                this._alertService.error(error);
                this.loading = false;
            });
    }

    signup() {
        this.loading = true;
        this._userService.create(this.model).subscribe(
            data => {
                this.showAlert('alertSignin');
                this._alertService.success(
                    'Thank you. To complete your registration please check your email.',
                    true);
                this.loading = false;
                LoginCustom.displaySignInForm();
                this.model = {};
            },
            error => {
                this.showAlert('alertSignup');
                console.log(error);
                this._alertService.error(error);
                this.loading = false;
            });
    }

    forgotPass() {
        this.loading = true;
        this._userService.forgotPassword(this.model.email).subscribe(
            data => {
                this.showAlert('alertSignin');
                this._alertService.success(
                    'Te enviamos um e-mail com as instruções para recuperação de senha!',
                    true);
                this.loading = false;
                LoginCustom.displaySignInForm();
                this.model = {};
            },
            error => {
                this.showAlert('alertForgotPass');
                let errorMessage = error;
                if (error.status == 404)
                {
                    errorMessage = 'E-mail não encontrado!';
                }
                this._alertService.error(errorMessage);
                this.loading = false;
            });
    }

    showAlert(target) {
        this[target].clear();
        let factory = this.cfr.resolveComponentFactory(AlertComponent);
        let ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }

    termsConditionsModal()
    {
        $("#termsConditions").modal("show");
    }
}